@import "variables";

body, html {
    width: 100%;
    height: 100%; }

* {
    box-sizing: border-box;
    font-family: arial;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none; }

.clear {
    clear: both; }

header {
    width: 300px;
    height: 100%;
    float: left;
    background-color: $dark-blue;
    position: fixed;
    left: 0;
    top: 0;

    .active {
        li {
            background-color: lighten($dark-blue, 10%)!important; } }

    span {
        // background-color: $text-white
        padding: 15px;
        display: block;
        margin-bottom: 40px;
        color: $text-white;
        border-bottom: 1px solid $text-white; }


    ul {
        // padding: 20px

        li {
            padding: 15px; }

        a {
            text-transform: uppercase;
            color: $text-white;
            font-weight: bold;
            font-size: 12px;

            &:hover li {
                background-color: lighten($dark-blue, 10%); }

            &:last-child li {
                background-color: $btn-red!important;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center; } } } }



.main-body {
    // background-color: $bg-blue
    margin-left: 300px;
    min-height: 100%;
    padding: 20px; }

input {
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid $dark-blue; }

button {
    background-color: $btn-green;
    color: white;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 4px;
    margin-top: 50px;
    border: none;
    transition: all .1s ease-in-out;
    cursor: pointer;
    font-size: 14px;

    &:hover {
        background-color: $btn-green-dark;
        font-weight: bold; } }
.tip {
    margin-bottom: 30px;
    color: $dark-blue; }

.btns {
    .undo-btn {
        background-color: $btn-red;
        color: white;
        padding: 10px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 14px;
        margin-right: 15px;

        &:hover {
            background-color: $btn-red-dark;
            font-weight: bold; } } }

.categories-section {
    font-size: 0;
    h2 {
        text-transform: uppercase;
        margin-bottom: 20px;
        color: $dark-blue;
        font-size: 20px; }

    input {
        margin-bottom: 10px;
        margin-right: 5px; }

    .fyzicka-wrap {
        margin-bottom: 50px; }

    .category-add-btn {
        cursor: pointer;
        border-radius: 100%;
        background-color: $bg-blue;
        color: white;
        font-size: 15px;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px; } }

.demands-filters {
    margin-top: 10px;
    display: block;

    button {
        margin-top: 0; }

    label {
        margin-right: 30px;
        display: inline-block;

        span {
            font-weight: bold;
            display: block;
            margin-bottom: 5px; } }


    input[type="number"] {
        width: 90px; }

    select {
        height: 38px;
        border: 1px solid $dark-blue;
        border-radius: 4px;
        padding: 10px; } }


.demands-table {
    table {
        margin-top: 30px;
        width: 100%;
        box-shadow: 0 0 12px 2px $dark-blue; }

    th, td {
        padding: 20px 10px;

        &:first-child, &:last-child {
            padding: 0; }

        &:first-child {
            padding-left: 5px; } }

    th {
        text-align: left; }

    tr {
        background-color: #f1f1f1;
        color: black;
        font-size: 13px;

        &:nth-of-type(even) {
            background-color: darken(#f1f1f1, 10%); } }

    tr {
        a {
            color: white;
            font-size: 12px;
            padding: 10px 5px;
            margin-right: 5px;
            background-color: $btn-green; } }

    td {
        button {
            margin-top: 0;
            display: inline-block;
            border-radius: 2px;
            background-color: $btn-red; } }

    .pagination {
        text-align: right;
        margin-top: 40px;
        font-size: 0;

        li {
            font-size: 20px;
            display: inline-block;
            // border-left: none
            margin-right: 5px;

            a {
                border: 2px solid $dark-blue;
                padding: 5px 10px;
                color: $dark-blue;

                &:hover {
                    background-color: $bg-blue;
                    border-color: $bg-blue;
                    color: white; } } }

        .active {
            span {
                border: 2px solid $bg-blue;
                padding: 5px 10px;
                color: $dark-blue;
                background-color: $bg-blue;
                color: white; } } } }






.edit-detail {
    h2 {
        color: $dark-blue;
        margin-bottom: 20px;

        .h2-deleted, .h2-finished {
            font-size: 15px;
            color: $btn-red;
            border: 2px solid $btn-red;
            padding: 5px 10px;
            margin-bottom: 10px; }

        .h2-finished {
            border: 2px solid $btn-green;
            color: $btn-green; } }

    label {
        width: 33%;
        float: left;
        margin-bottom: 20px;

        input {
            height: 30px;
            border-radius: 2px; }

        span {
            display: block;
            font-weight: bold;
            font-size: 15px;
            margin-bottom: 10px; }

        textarea {
            width: calc(100% - 20px);
            height: 100px;
            padding: 10px; } }

    .text-area {
        display: block; }

    form {
        border-top: 2px solid #f1f1f1;
        padding-top: 20px;
        margin-bottom: 20px;
        h3 {
            margin-bottom: 20px; } } }

.edit-info-wrap {
    margin-bottom: 20px;
    display: block;

    .edit-info {
        display: inline-block;
        margin-right: 10px;

        span {
            font-weight: bold; } } }

.law-demand #law-add-submit {
    margin-top: 0!important; }

.lawyer-search, .law-demand {
    position: relative;

    .btns {
        float: right;
        display: block;
        margin-top: 60px; }

    .law-info {
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;

        table {
            text-align: left;

            td {
                padding-left: 20px;
                padding-bottom: 10px; }

            th {
                padding-bottom: 10px; } } }


    #search-law-result, #search-law-result2 {
        position: absolute;
        padding: 5px;
        color: white;

        span {
            margin-right: 10px; }

        .law-result, .law-result2 {
            padding: 5px;
            padding: 10px 0;
            background-color: $dark-blue;

            &:hover {
                background-color: lighten($dark-blue, 10%);
                cursor: pointer; } } } }

.law-info-buttons {
    float: right;
    form {
        border-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        display: inline-block; }

    .undo-btn {
        background-color: $btn-green!important; }

    button {
        margin-top: 0;
        background-color: $btn-red; } }


.editLaw-page {
    h2 {
        color: $dark-blue;
        margin-bottom: 20px; }

    label {
        margin-right: 10px;
        margin-bottom: 20px;
        display: inline-block;
        vertical-align: top;

        span {
            display: block; }


        textarea {
            width: 300px;
            height: 200px;
            padding: 15px; } } }



.lawyers-page {
    .editLaw-page {
        textarea {
            height: 100px!important; }
        .btns {
            margin-top: -40px;
            text-align: right; } }

    .demands-table {
        margin-top: 30px!important;
        border-top: 2px solid #f1f1f1;
        h2 {
            margin-top: 20px;
            color: $dark-blue; } } }


.admin-login {
    background-color: $bg-blue;

    a {
        color: $dark-blue; }

    h2 {
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 20px;
        margin-bottom: 20px;
        color: $btn-red; }

    form {
        display: table;
        margin: 0 auto;
        margin-top: 150px; }

    label {
        display: block;
        color: white!important;
        text-transform: uppercase;
        margin-bottom: 5px; }

    input {
        margin-bottom: 20px; }

    button {
        margin-top: 0;
        display: block;
        float: none;
        width: 223px;
        border: 1px solid $btn-green-dark; } }
