$bg-blue: #3AAFA9;
$dark-blue: #2B7A78;
$selected-blue: #46BBB5;

$text-white: #DEF2F1;

$btn-green: #28B351;
$btn-green-dark: #19A442;

$btn-red: #AF563A;
$btn-red-dark: #A44B2F;


$poppins: 'Poppins', sans-serif;
$roboto: 'Roboto', sans-serif;
$ruda-roboto: 'Ruda', 'Roboto';
$ruda: 'Ruda', sans-serif;
